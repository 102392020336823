import { useEffect, useState } from "react";
import { useNotificationContext, Button, IconButton, Snackbar } from "cerulean";
import { useLocalization } from "@fluent/react";
import ApiHttp from "byzantine/src/ApiHttp";
import DualApprovalRequest from "byzantine/src/DualApprovalRequest";
import Pagination from "byzantine/src/Pagination";
import Filters from "byzantine/src/filters";
import { useCurrentUser } from "../contexts/CurrentUserContext";
import PageNavigation from "../page_navigation/PageNavigation";
import DualApprovalPendingTable from "./DualApprovalPendingTable";
import { SelectedDualApprovalsMapperType } from "./DualApprovalContainer";
import DualApprovalRejectDialog from "./DualApprovalRejectDialog";
import styles from "./DualApproval.module.scss";

type DualApprovalPendingContainerType = {
  updateTotal: (total: number) => void;
  selectedDualApprovals: SelectedDualApprovalsMapperType;
  setSelectedDualApprovals: React.Dispatch<
    React.SetStateAction<SelectedDualApprovalsMapperType>
  >;
  recipients: API.Recipient[];
  pageNumber: number;
};

export const PAGE_SIZE = 20;
const APPROVE_ACTION = "approve";
const REJECT_ACTION = "reject";

export const usePaginatedApprovals = (
  endpoint: string,
  keyname: string,
  page: number,
  pageSize: number,
  recipients: API.Recipient[],
) => {
  const [dualApprovals, setDualApprovals] = useState<DualApprovalRequest[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchPaginatedDualApprovals = async () => {
    setLoading(true);
    try {
      const response = await ApiHttp.fetch(
        endpoint,
        {},
        { page, per_page: pageSize },
      );
      const deserializedApprovals = response[keyname].map(
        (request: API.AnyDualApproval) =>
          DualApprovalRequest.deserialize(request, recipients),
      );
      setTotal(response.meta.total);
      setDualApprovals(deserializedApprovals);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchPaginatedDualApprovals,
    dualApprovals,
    setDualApprovals,
    total,
    loading,
  };
};

const useApprovalActions = (
  selectedDualApprovals: DualApprovalPendingContainerType["selectedDualApprovals"],
  setSelectedDualApprovals: DualApprovalPendingContainerType["setSelectedDualApprovals"],
) => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useCurrentUser();

  const processApproval = async (
    action: typeof APPROVE_ACTION | typeof REJECT_ACTION,
    approval?: DualApprovalRequest,
  ) => {
    setLoading(true);

    const approvals = approval
      ? [approval]
      : Object.values(selectedDualApprovals);

    const promises = approvals.map((item) =>
      action === APPROVE_ACTION ? item.approve() : item.reject(),
    );

    const results = await Promise.allSettled(promises);

    const updatedApprovals = { ...selectedDualApprovals };
    results.forEach((result) => {
      if (result.status === "fulfilled") {
        const { uuid } = result.value.approval_request;
        delete updatedApprovals[uuid];
      }
    });

    setSelectedDualApprovals(updatedApprovals);
    setLoading(false);

    return results;
  };

  const handleToggleApproval = (dualApproval: DualApprovalRequest) => {
    const updatedApprovals = { ...selectedDualApprovals };
    const { uuid } = dualApproval;
    if (updatedApprovals[uuid]) {
      delete updatedApprovals[uuid];
    } else {
      updatedApprovals[uuid] = dualApproval;
    }
    setSelectedDualApprovals(updatedApprovals);
  };

  const selectAllApprovals = (approvals: DualApprovalRequest[]) => {
    const updatedApprovals = { ...selectedDualApprovals };
    approvals.forEach((approval) => {
      if (approval.requester.uuid !== currentUser?.id) {
        updatedApprovals[approval.uuid] = approval;
      }
    });
    setSelectedDualApprovals(updatedApprovals);
  };

  const deselectAllApprovals = () => {
    setSelectedDualApprovals({});
  };

  return {
    handleApprove: () => processApproval(APPROVE_ACTION),
    handleReject: () => processApproval(REJECT_ACTION),
    handleToggleApproval,
    handleSingleApprove: (dualApproval: DualApprovalRequest) =>
      processApproval(APPROVE_ACTION, dualApproval),
    handleSingleReject: (dualApproval: DualApprovalRequest) =>
      processApproval(REJECT_ACTION, dualApproval),
    selectAllApprovals,
    deselectAllApprovals,
    loading,
  };
};

const DualApprovalPendingContainer = ({
  updateTotal,
  selectedDualApprovals,
  setSelectedDualApprovals,
  recipients,
  pageNumber,
}: DualApprovalPendingContainerType) => {
  const { l10n } = useLocalization();
  const [page, setPage] = useState(pageNumber);
  const { sendNotification } = useNotificationContext();
  const [showRejectModal, setShowRejectModal] = useState(false);

  const {
    fetchPaginatedDualApprovals,
    dualApprovals,
    setDualApprovals,
    total,
    loading,
  } = usePaginatedApprovals(
    "approval_requests",
    "approval_requests",
    page,
    PAGE_SIZE,
    recipients,
  );

  // wrapper to show error notification
  const handleFetchPaginatedApprovals = () => {
    fetchPaginatedDualApprovals().then((result) => {
      if (!result.success) {
        sendNotification({
          type: "negative",
          text: l10n.getString("error-generic"),
        });
      }
    });
  };

  const {
    handleApprove,
    handleReject,
    handleToggleApproval,
    selectAllApprovals,
    deselectAllApprovals,
    handleSingleApprove,
    handleSingleReject,
    loading: actionLoading,
  } = useApprovalActions(selectedDualApprovals, setSelectedDualApprovals);

  useEffect(() => {
    handleFetchPaginatedApprovals();
  }, [page]);

  useEffect(() => {
    updateTotal(total);
  }, [total, updateTotal]);

  const handleAction = async (
    action: typeof APPROVE_ACTION | typeof REJECT_ACTION,
    dualApproval?: DualApprovalRequest,
  ) => {
    const actionHandlers = {
      [APPROVE_ACTION]: dualApproval
        ? () => handleSingleApprove(dualApproval)
        : handleApprove,
      [REJECT_ACTION]: dualApproval
        ? () => handleSingleReject(dualApproval)
        : handleReject,
    };

    const handler = actionHandlers[action];
    const results = await handler();

    if (results?.some((result) => result.status === "rejected")) {
      sendNotification({
        type: "negative",
        text: l10n.getString("error-generic"),
      });
    } else {
      const numSelected = results?.filter(
        (result) => result.status === "fulfilled",
      ).length;
      const actionKey =
        action === APPROVE_ACTION
          ? "approval-notif-items-approved"
          : "approval-notif-items-rejected";
      if (numSelected) {
        sendNotification({
          type: "success",
          text: l10n.getString(actionKey, { numSelected }),
        });
      }

      handleFetchPaginatedApprovals();
    }
  };

  const calculateTotalAmount = (
    selectedApprovals: SelectedDualApprovalsMapperType,
  ) => {
    const calculatedTotalAmount = Object.values(selectedApprovals).reduce(
      (totalAmount, item) => totalAmount + (item.action?.dollarAmount || 0),
      0,
    );
    return Filters.currency(calculatedTotalAmount as Dollars, {
      hasDecimal: true,
    });
  };

  const pagination = new Pagination({
    total,
    page,
    pageSize: PAGE_SIZE,
    navigatePage: setPage,
  });

  const toggleRejectDialog = () => {
    setShowRejectModal(!showRejectModal);
  };

  const totalSelected = Object.keys(selectedDualApprovals).length;

  return (
    <>
      <DualApprovalPendingTable
        loading={loading || actionLoading}
        setDualApprovals={setDualApprovals}
        dualApprovalRequests={dualApprovals}
        selectedDualApprovals={selectedDualApprovals}
        handleSingleApprove={(dualApproval: DualApprovalRequest) =>
          handleAction(APPROVE_ACTION, dualApproval)
        }
        handleSingleReject={(dualApproval: DualApprovalRequest) =>
          handleAction(REJECT_ACTION, dualApproval)
        }
        handleToggleCheckbox={handleToggleApproval}
        handleToggleCheckboxHeader={(isSelected) =>
          isSelected
            ? selectAllApprovals(dualApprovals)
            : deselectAllApprovals()
        }
      />
      <PageNavigation pagination={pagination} marginClasses="margin--all--l" />
      <div className={styles.stickyContainer}>
        <Snackbar isActive={Boolean(totalSelected)}>
          <span className="fontColor--primary fontWeight--semibold">
            {`${totalSelected} ${l10n.getString("snackbar-payment-selected", {
              totalSelected,
            })}`}
          </span>
          <span className="fontColor--secondary">
            {calculateTotalAmount(selectedDualApprovals)}
          </span>
          <IconButton
            kind="action"
            label="Close Actions"
            name="x"
            textSize="l"
            onClick={deselectAllApprovals}
          />
          <Snackbar.Divider />
          <Snackbar.ButtonGroup>
            <Button
              kind="secondary"
              label="Reject"
              onClick={toggleRejectDialog}
            />
            <Button
              kind="primary"
              label="Approve"
              onClick={() => handleAction(APPROVE_ACTION)}
            />
          </Snackbar.ButtonGroup>
        </Snackbar>
      </div>
      <DualApprovalRejectDialog
        isOpen={showRejectModal}
        handleClose={toggleRejectDialog}
        handleReject={() => handleAction(REJECT_ACTION)}
        totalSelected={Object.keys(selectedDualApprovals).length}
      />
    </>
  );
};

export default DualApprovalPendingContainer;
